import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/SEO"

const IvanaMrgan = () => {
  return (
    <Layout>
      <SEO title="IvanaMrgan" description="Klinički psiholog, KBT i Shema psihoterapeut koji radi online i u direktnom kontaktu u Novom Zagrebu na Engleskom i Hrvatskom jeziku." />
      <main className="page">
      <div className="profilePage">
            <div>
              <StaticImage
              src="../assets/images/ivana.jpeg"
              alt="Ivana_Mrgan"
              className="ivana-profile-img"
              placeholder="DOMINANT_COLOR"
              />
            </div>
            <div className="profileText">
                <h1><strong>Ivana Mrgan</strong> <br class="mobile-break"/><span className="kurziv">univ. spec. clin. psych.</span></h1>
                <h3>Drago mi je da si tu!</h3>
                <p>Vjerojatno čitaš ovo s mješavinom znatiželje, zabrinutosti i mnoštva pitanja. Sve to je dobro došlo, 
                  važno i potrebno u ovoj suradnji o kojoj promišljaš. Pretpostavljam da vidiš da ti neko preslagivanje 
                  ne gine (origami jezikom) i veselim se prilici da budem dio te pustolovine.
                </p>
                <p>Otvorenost prema različitim iskustvima, empatija i autentični odnosi su nešto što me pratilo u karijeri, 
                  a to su ujedno i vrijednosti koje donosim sa sobom u naš odnos ako se odlučiš za suradnju. Posebno me veseli
                  praksa temeljena na istraživanjima, multikulturalizam i kulturno osjetljiv pristup klijentima.
                </p>
                <p>Ovo posljednje se razvilo i uzelo maha tijekom sedmogodišnjeg rada u inozemstvu gdje sam uglavnom radila s imigrantima 
                  u sustavu obrazovanja i privatnoj kliničkoj praksi na poslovima psihološke procjene, savjetovanja te psihoterapije za 
                  djecu, adolescente i odrasle. U tom razdoblju sam i pojačano radila u korporativnom okruženju za nekoliko međunarodnih 
                  kompanija koje se bave potporom zaposlenicima (EAP).
                </p>
                <p>U tom razdoblju sam završlila i poslijediplomski stručni studij s fokusom na učinkovitost kulturološki adaptirane 
                  Kognitivno-bihevioralne terapije (KBT).
                </p>
                <p>Osim Kognitivno-bihevioralne terapije sam se zaljubila i u Terapiju prihvaćanjem i posvećenošću (ACT), a posljednjim 
                  godina sam se više posvetila i Shema terapiji, usmjernoj na teškoće u odnosima i dugotrajnije smetnje. Više o tome što 
                  možeš očekivati u Shema terapiji možeš pročitati <Link to="../blogEng/schema-therapy-benefits">ovdje</Link>.
                </p>
                <p>U početku karijere sam imala raznovrsna poslovna iskustva koja su rezultirala i stručnim usavršavanjima vezanim uz 
                  upravljanje ljudskim resursima i savjetovanje u razvoju karijere. U to vrijeme sam radila i kao stručni suradnik u školi, savjetnik u centru za studente te surađivala s Anom kako bi 
                  stvorili Apsihu, centar za osobni razvoj u kakav bismo i same voljele ušetati…
                </p>
                <p>Uz Apsihu imam i drugih ljubavi. Šarolike su kao i moja iskustva, slike neumornog i znatiželjnog duha. Tu su moja obitelj, 
                  terapijska i međunarodna zajednica, parkour, putovanja, volonterizam, pisanje i kuhanje.
                </p>
                <p>Ovdje su neke od <Link to="../Services">usluge</Link> koje nudimo.
                </p>
                  <p>Presložimo se zajedno!
                </p>
              </div>
              <div className="backBtn">
              <Link to="/About/#ivana"  className="btn">
                &laquo; Povratak 
              </Link>
            </div>
            </div>
      </main>
    </Layout>
  )
}

export default IvanaMrgan
